import {
  Box,
  HStack,
  Icon,
  Stack,
  Text,
  type BoxProps,
} from "@chakra-ui/react";
import type { MessageRole } from "@prisma/client";
import React from "react";
import { ChevronRight } from "react-feather";
import { ChatMessagesMessageFeedback } from "~/components/organisms/ChatMessagesMessageFeedback";
import {
  ChatMessagesMessageSources,
  type ChatMessagesMessageSourcesProps,
} from "~/components/organisms/ChatMessagesMessageSources";

export interface ChatMessagesMessageProps extends BoxProps {
  role: MessageRole | "error" | "support";
  id?: string;
  liked?: boolean | null;
  sources?: ChatMessagesMessageSourcesProps["sources"];
}

export const ChatMessagesMessage: React.FC<ChatMessagesMessageProps> = ({
  children,
  role,
  id,
  sources,
  liked,
  ...props
}) => {
  return (
    <Stack
      alignSelf={
        {
          ["user"]: "flex-end",
          ["assistant"]: "flex-start",
          ["error"]: "flex-start",
          ["support"]: "flex-start",
        }[role]
      }
      {...props}
    >
      <HStack
        ml={role === "user" ? 8 : 0}
        mr={["assistant", "error"].includes(role) ? 8 : 0}
        className="group"
      >
        <Box
          bg={
            {
              ["user"]: "blue.500",
              ["assistant"]: "yellow.100",
              ["support"]: "yellow.100",
              ["error"]: "red.50",
            }[role]
          }
          color={
            {
              ["user"]: "blue.50",
              ["assistant"]: "yellow.900",
              ["support"]: "yellow.900",
              ["error"]: "red.800",
            }[role]
          }
          p={4}
          borderRadius="lg"
          boxShadow="sm"
          maxW="prose"
          w="full"
        >
          {children}
        </Box>
        {role === "assistant" && id ? (
          <ChatMessagesMessageFeedback
            display={["none", null, "flex"]}
            opacity={0.4}
            _groupHover={{ opacity: 1 }}
            transition="opacity 0.2s ease-in-out"
            messageId={id}
            liked={liked}
          />
        ) : null}
      </HStack>
      {sources?.length ? (
        <>
          <ChatMessagesMessageSources
            display={["none", null, "flex"]}
            sources={sources}
          />
          <HStack
            display={["flex", null, "none"]}
            justify="space-between"
            align="flex-start"
          >
            <Box
              as="details"
              alignSelf="flex-start"
              className="group"
              sx={{
                "&:not([open]) summary": {
                  pb: 4,
                },
                "&[open] .icon": {
                  transform: "rotate(90deg)",
                },
                ".sources": {
                  maxHeight: 0,
                },
                "&[open] .sources": {
                  maxHeight: "120vh",
                },
              }}
            >
              <HStack
                as="summary"
                fontSize="md"
                color="blue.600"
                listStyleType="none"
                sx={{
                  "&::marker": {
                    display: "none",
                  },
                  "&::-webkit-details-marker": {
                    display: "none",
                  },
                }}
              >
                <Icon
                  as={ChevronRight}
                  className="icon"
                  transition="all 0.2s ease-in-out"
                />
                <Text>Visa källor ({sources.length})</Text>
              </HStack>
              <ChatMessagesMessageSources
                className="sources"
                sources={sources}
                transition="all 0.4s ease-in-out"
              />
            </Box>
            {role === "assistant" && id ? (
              <ChatMessagesMessageFeedback
                position="relative"
                top={-2}
                messageId={id}
                liked={liked}
              />
            ) : null}
          </HStack>
        </>
      ) : null}
    </Stack>
  );
};
