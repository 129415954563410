import {
  Button,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Stack,
  Textarea,
  type BoxProps,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { Send } from "react-feather";
import { useFormContext } from "react-hook-form";
import { useMountedState } from "react-use";

interface ChatInputProps extends BoxProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  onMessage(message: string): unknown;
}

export const ChatInput: React.FC<ChatInputProps> = ({
  isLoading,
  isDisabled,
  onMessage,
  ...props
}) => {
  const {
    formState: { isValid },
    register,
    handleSubmit,
    reset,
  } = useFormContext<{
    message: string;
  }>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);

  const onSubmit = handleSubmit(({ message }) => {
    onMessage(message);
    reset();
  });

  const isMounted = useMountedState();

  return (
    <Stack p={4} {...props}>
      <HStack as="form" ref={formRef} onSubmit={onSubmit} align="flex-end">
        <FormControl>
          <Textarea
            rows={1}
            resize="none"
            bg="white"
            placeholder="Spørg mig om noget!"
            overflow="hidden"
            _focusVisible={{ borderColor: "blue.300" }}
            isDisabled={isDisabled && isMounted()}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                const formEl = formRef.current as HTMLFormElement;
                void (formEl?.requestSubmit
                  ? formEl.requestSubmit()
                  : onSubmit());
                const textarea = event.target as HTMLTextAreaElement;
                if (textarea?.style?.height) {
                  textarea.style.height = "auto";
                }
              }
            }}
            {...register("message", {
              required: true,
              minLength: 3,
              onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
                event.target.style.height = "auto";
                event.target.style.height = `${event.target.scrollHeight}px`;
              },
              onBlur(event: React.FocusEvent<HTMLTextAreaElement>) {
                event.target.style.height = "auto";
                event.target.style.height = `${event.target.scrollHeight}px`;
              },
            })}
          />
        </FormControl>
        <IconButton
          display={["inline-flex", "none"]}
          type="submit"
          isDisabled={!isValid}
          aria-label="Send"
          isLoading={isLoading}
          icon={<Icon as={Send} />}
        />
        <Button
          display={["none", "inline-flex"]}
          type="submit"
          isDisabled={!isValid || isDisabled}
          isLoading={isLoading}
          rightIcon={<Icon as={Send} />}
        >
          Send
        </Button>
      </HStack>
    </Stack>
  );
};
