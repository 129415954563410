import {
  ButtonGroup,
  HStack,
  Icon,
  IconButton,
  useToast,
  type BoxProps,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { usePlausible } from "next-plausible";
import React from "react";
import { ThumbsDown, ThumbsUp } from "react-feather";
import { api } from "~/utils/api";

interface ChatMessagesMessageFeedbackProps extends BoxProps {
  messageId: string;
  liked?: boolean | null;
}

export const ChatMessagesMessageFeedback: React.FC<
  ChatMessagesMessageFeedbackProps
> = ({ messageId, liked, ...props }) => {
  const plausible = usePlausible();
  const queryClient = useQueryClient();
  const toast = useToast();

  const update = api.message.update.useMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(getQueryKey(api.chat.get));
    },
  });

  const onUpdate = (like: boolean) => {
    plausible("chat:feedback", { props: { messageId, like } });
    update.mutate({ id: messageId, liked: like });
    if (like) {
      toast({
        icon: (
          <HStack>
            <Icon as={ThumbsUp} />
          </HStack>
        ),
        title: "Tack för din feedback!",
        duration: 3000,
        isClosable: true,
        containerStyle: {
          mb: 16,
        },
      });
    } else {
      toast({
        icon: (
          <HStack>
            <Icon as={ThumbsDown} />
          </HStack>
        ),
        title: "Tack för att du tycker till!",
        description:
          "Men tråkigt att svaret inte var till hjälp. Skicka gärna feedback så kan jag undersöka om det går att förbättra!",
        duration: 7000,
        isClosable: true,
        containerStyle: {
          mb: 16,
        },
      });
    }
  };

  return (
    <ButtonGroup
      spacing={0}
      _hover={{ "& > *:not(:hover)": { opacity: 0.5 } }}
      {...props}
    >
      <IconButton
        variant="ghost"
        colorScheme="blue"
        icon={
          <Icon as={ThumbsUp} fill={liked === true ? "blue.600" : undefined} />
        }
        aria-label="Gilla"
        _hover={{ opacity: 1 }}
        isDisabled={liked === true || update.isLoading}
        onClick={() => onUpdate(true)}
      />
      <IconButton
        variant="ghost"
        colorScheme="red"
        icon={
          <Icon
            as={ThumbsDown}
            fill={liked === false ? "red.600" : undefined}
          />
        }
        aria-label="Ogilla"
        _hover={{ opacity: 1 }}
        isDisabled={liked === false || update.isLoading}
        onClick={() => onUpdate(false)}
      />
    </ButtonGroup>
  );
};
