import { Text } from "@chakra-ui/react";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import React from "react";
import ReactMarkdown from "react-markdown";

interface MarkdownProps {
  text: string;
}

export const Markdown: React.FC<MarkdownProps> = ({ text }) => {
  return (
    <ReactMarkdown
      components={ChakraUIRenderer({
        p: ({ children }) => <Text mb={0}>{children}</Text>,
        a: ({ children }) => <Text as="span">{children}</Text>,
      })}
      skipHtml
    >
      {text}
    </ReactMarkdown>
  );
};
