import {
  Box,
  Heading,
  Link,
  Stack,
  Text,
  type BoxProps,
} from "@chakra-ui/react";
import type { MessageSource } from "@prisma/client";
import React from "react";

export interface ChatMessagesMessageSourcesProps extends BoxProps {
  sources: Array<
    Pick<
      MessageSource,
      "id" | "url" | "title" | "description" | "sectionId" | "sectionTitle"
    >
  >;
}

export const ChatMessagesMessageSources: React.FC<
  ChatMessagesMessageSourcesProps
> = ({ sources, ...props }) => {
  return (
    <Stack
      as="ul"
      direction={["column", null, "row"]}
      listStyleType="none"
      alignItems="start"
      mt={2}
      mb={4}
      {...props}
    >
      {sources.map((source) => (
        <Stack
          as="li"
          key={source.id}
          maxW="2xs"
          bg="white"
          border="1px solid"
          borderColor="blue.200"
          borderRadius="lg"
          boxShadow="sm"
          transition="all 0.2s"
          _hover={{
            boxShadow: "md",
          }}
        >
          <Box
            p={4}
            as={Link}
            href={`${source.url}#${source.sectionId || ""}`}
            target="_blank"
            rel="noopener noreferrer"
            _hover={{
              textDecoration: "none",
            }}
          >
            <Heading as="h3" fontSize="sm" noOfLines={2}>
              {source.sectionTitle || source.title}
            </Heading>
            {source.sectionTitle && (
              <Text mt={2} noOfLines={1} fontSize="xs">
                {source.title}
              </Text>
            )}
            <Text mt={2} color="gray.600" fontSize="xs" noOfLines={3}>
              {source.description}
            </Text>
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};
