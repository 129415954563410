import { Spinner, Stack, type BoxProps } from "@chakra-ui/react";
import type { Message } from "@prisma/client";
import React from "react";
import { Markdown } from "~/components/Markdown";
import {
  ChatMessagesMessage,
  type ChatMessagesMessageProps,
} from "./ChatMessagesMessage";

export interface ChatMessagesProps extends BoxProps {
  messages: (Pick<Message, "text" | "createdAt"> &
    Partial<Pick<Message, "id" | "liked">> & {
      sources?: ChatMessagesMessageProps["sources"];
    } & {
      role: ChatMessagesMessageProps["role"];
    })[];
  pendingMessage?: string;
  errorMessage?: string;
  isLoading?: boolean;
}

export const ChatMessages: React.FC<ChatMessagesProps> = ({
  messages,
  pendingMessage,
  errorMessage,
  isLoading,
  ...props
}) => {
  return (
    <Stack as="ul" listStyleType="none" p={4} {...props}>
      {messages.map((message) => (
        <Stack
          as="li"
          key={
            message.id ??
            `${message.role}-${message.createdAt.valueOf().toString()}`
          }
        >
          <ChatMessagesMessage
            id={message.id}
            liked={message.liked}
            role={message.role}
            sources={message.sources}
          >
            <Stack>
              <Markdown text={message.text} />
            </Stack>
          </ChatMessagesMessage>
        </Stack>
      ))}
      {pendingMessage ? (
        <>
          <Stack as="li" key="pending">
            <ChatMessagesMessage role="user">
              {pendingMessage}
            </ChatMessagesMessage>
          </Stack>
        </>
      ) : null}
      {isLoading ? (
        <Stack as="li" key="loading">
          <ChatMessagesMessage role="assistant">
            <Stack opacity={0.7}>
              <Spinner size="sm" color="yellow.700" />
            </Stack>
          </ChatMessagesMessage>
        </Stack>
      ) : null}
      {errorMessage ? (
        <Stack as="li" key="error">
          <ChatMessagesMessage role="error">{errorMessage}</ChatMessagesMessage>
        </Stack>
      ) : null}
    </Stack>
  );
};
